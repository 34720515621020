<template>
  <div>
    <dados-financeiros
      :blockInputs="blockInputs"
      v-if="((proposta || {}).Endorsement || {}).InsurancePremiumType == 'C'"
    />

    <template v-else>
      <vs-alert color="#166aa8" class="h-auto">
        <i class="onpoint-warning icon-font mr-3"></i>
        Proposta de endosso não gerou prêmio a cobrar, prossiga com a emissão.
      </vs-alert>

      <revisao-premio :canUpdate="false" v-if="policyHolderBankInfo" />
    </template>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="end">
        <vs-button
          v-if="proposta.Endorsement.EndorsementReasonId == 18"
          class="button-default mr-4"
          @click.native="salvarProposta()"
          type="filled"
        >
          <span class="font-semibold">
            Salvar Endosso
          </span>
        </vs-button>
        <vs-button
          v-if="
            podeEmitirApolice && proposta.Endorsement.EndorsementTypeId !== 1
          "
          @click="imprimirMinuta()"
          class="button-default mr-4"
          type="filled"
        >
          <span class="font-semibold pr-2 pl-2"> Imprimir minuta </span>
        </vs-button>
        <div v-if="proposta.Endorsement.EndorsementReasonId != 18">
          <vs-button
            v-if="podeEmitirApolice"
            @click="comfirmaEmissaoEndossoModal = true"
            color="primary"
          >
            <span class="font-semibold pl-2"> Emitir endosso </span>
          </vs-button>
        </div>
      </vs-col>
    </vs-row>

    <vs-popup
      class="fix-scroll"
      title=""
      :active.sync="comfirmaEmissaoEndossoModal"
      id="confirmacao-modal"
    >
      <div class="flex flex-col items-center pt-10">
        <i class="onpoint-warning icon-font text-warning"></i>

        <div class="text-confirmation">
          <p class="line-1">Tem certeza que deseja prosseguir?</p>
          <p class="line-2" style="color: red" v-if="!Excelsior">
            Essa ação não poderá ser desfeita!
          </p>
          <p class="line-2" v-else>Essa ação não poderá ser desfeita.</p>
        </div>
      </div>

      <div class="text-right px-6 pb-6">
        <vs-button
          color="grey"
          class="mt-8 mr-3"
          @click="comfirmaEmissaoEndossoModal == false"
          >Cancelar</vs-button
        >
        <vs-button
          color="primary"
          class="mt-8 mr-3"
          type="filled"
          @click="showModalTermosAceite()"
          >Prosseguir</vs-button
        >
      </div>
    </vs-popup>

    <vs-popup
      class="fix-scroll"
      id="termos-endosso-modal"
      :button-close-hidden="true"
      title=" "
      :active.sync="termosAceiteModal"
    >
      <div class="px-6">
        <h2 class="font-bold text-primary">Termo e declaração</h2>
        <p class="font-bold py-5">Leia os termos para solicitação de endosso</p>
        <div class="flex flex-col py-5 px-3 bg-grey-300 text-justify">
          <span v-html="HTMLTerm"></span>
        </div>

        <vs-checkbox class="mt-8" v-model="termosAceite">
          Declaro que li e estou ciente das condições acima.
        </vs-checkbox>

        <div class="text-right">
          <vs-button
            color="grey"
            class="mt-8 mr-3"
            @click="termosAceiteModal = false"
            >Cancelar</vs-button
          >
          <el-button
            :disabled="!termosAceite"
            type="primary"
            @click="emitirApoliceEndosso()"
            :loading="loadingButton"
            >Prosseguir</el-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import DadosFinanceiros from "@/views/propostas/components/emissao/DadosFinanceiros.vue";
import RevisaoPremio from "./components/RevisaoPremio.vue";
import { mapActions, mapGetters } from "vuex";
import * as Enums from "@/utils/enums";
import environment from "@/environment";
import axiosInstance from "@/axios";
export default {
  name: "editar-endosso",
  components: { DadosFinanceiros, RevisaoPremio },
  data() {
    return {
      termosAceite: false,
      termosAceiteModal: false,
      comfirmaEmissaoEndossoModal: false,
      Mapfre: false,
      Excelsior: false,
      loadingButton: false
    };
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),
    ...mapGetters("endosso-module", ["endosso"]),

    policyHolderBankInfo() {
      return (this.endosso.PolicyHolder || {}).BankAccount;
    },

    podeEmitirApolice: {
      get() {
        return this.proposta.StatusId == Enums.ProposalStatus.AguardandoEmissao;
      }
    },

    HTMLTerm() {
      let type = ((this.proposta || {}).Endorsement || {}).EndorsementTypeId;
      if (type == 1) {
        return `As informações e documentos incluídos no Portal do Corretor, para avaliação desta proposta de Cancelamento de Apólice, representam os elementos necessários para análise da solicitação de Cancelamento por parte da Seguradora, bem como, são verdadeiros e estão em conformidade com o Contrato Principal, ou similar.<br /><br />

        O corretor de Seguros, declara ainda, expressamente, que a apólice poderá ser cancelada pelo motivo aqui apresentado, quando o motivo decorrer de seu erro de preenchimento da proposta que serviu como base para emissão da Apólice a ser cancelada, se responsabiliza que a apólice não foi enviada para o Segurado/ou Tomador.<br /><br />

       O tomador, por meio próprio ou por seu Corretor de Seguros declara ter lido, compreendido e que está de acordo com as condições aqui estabelecidas, oriundo desta Proposta de Cancelamento de Apólice através deste Pedido de Emissão Digital de Cancelamento de Apólice de Seguro Garantia.`;
      } else {
        return `As informações e documentos incluídos no Portal do Corretor, para a avaliação desta Proposta de Endosso de Apólice, representam os elementos necessários para a análise do risco por parte da Seguradora, bem como, são verdadeiros e estão em conformidade com o Contrato Principal, ou similar, já garantido pela Apólice.<br /><br />

        O Tomador, por meio próprio ou por seu Corretor de Seguros, declara ainda, expressamente, ter lido, compreendido e que está de acordo com as condições aqui estabelecidas, incluindo as Condições Contratuais deste seguro, autorizando, portanto, a emissão do Endosso de Apólice, oriunda desta Proposta de Endosso de Apólice, através deste Pedido de Emissão Digital de Endosso de Apólice de seguro Garantia.<br /><br />`;
      }
    },

    blockInputs: {
      get() {
        return this.proposta.BlockChangesInProposal;
      }
    }
  },
  mounted() {
    var portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    var Mapfre = parseInt(environment.CompanyId.Mapfre);
    this.Mapfre = portalConfig.CompanyId == Mapfre ? true : false;
    var Excelsior = parseInt(environment.CompanyId.Excelsior);
    this.Excelsior = portalConfig.CompanyId == Excelsior ? true : false;
  },
  created() {
    this.$appInsights.trackPageView({
      name: "endosso-emissao",
      url: window.location.href
    });
  },
  methods: {
    ...mapActions("endosso-module", ["approveEndorsement"]),
    ...mapActions("proposta-module", ["getUpdateFinancialData"]),
    ...mapActions("propostas-module", ["gerarMinuta"]),

    showModalTermosAceite() {
      this.termosAceiteModal = true;
      this.comfirmaEmissaoEndossoModal = false;
    },

    async imprimirMinuta() {
      await this.$onpoint.loading(async() => {
        return await this.getUpdateFinancialData(this.proposta)
          .then(async() => {
            return await this.gerarMinuta(this.proposta.UniqueId)
              .then(response => {
                window.open(response, "_self");
              })
              .catch(errors =>
                this.$onpoint.errorModal(errors.response.data.Errors)
              );
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },
    salvarProposta() {
      let payload =  JSON.parse(JSON.stringify(this.proposta));
      if (!payload.ProposalId) {
        payload.ProposalId = payload.Id;
      }
      if (!payload.ContractualTerms) {
        payload.ContractualTerms = payload.ProposalContractualTerms;
        payload.ContractualTerms.map(x => {
          x.ContractualTermId =  x.Id
        });
      }
      this.$onpoint.loadingModal("Estamos salvando a proposta", () => {
        return axiosInstance
          .post("api/proposal/UpdateProposal", payload)
          .then(response => {
            this.$vs.notify({
              title: "Sucesso",
              text: "Proposta salva com sucesso!",
              color: "success"
            });
            setTimeout(() => {
              this.$router.push({
                name: "timeline-proposta",
                params: { propostaUniqueId: this.proposta.UniqueId }
              });
            }, 500);
          })
          .catch(errors => {
            this.$onpoint.errorModal(errors.response.data.Errors);
          });
      });
    },
    async emitirApoliceEndosso() {
      this.loadingButton = true;
      this.termosAceiteModal = false;
      await this.$onpoint.loading(async () => {
        return await this.approveEndorsement({
          ProposalUniqueId: this.$route.params.uniqueId,
          ComissionValue: this.proposta.ComissionValue,
          ComissionPercentage:
            this.proposta.ComissionPercentage != "NaN"
              ? this.proposta.ComissionPercentage
              : 0,
          Tax: this.proposta.RiskRate,
          InsurancePremium: this.proposta.InsurancePremium,
          NumberOfInstallment: this.proposta.NumberOfInstallment,
          GracePeriod: this.proposta.GracePeriod,
          CalculatePerComissionPercentage: this.proposta
            .CalculatePerComissionPercentage,
          CalculatePerRiskRate: this.proposta.CalculatePerRiskRate
        })
          .then(response => {
            this.loadingButton = false;
            this.termosAceiteModal = false;
            this.$onpoint.successModal(
              "Solicitação de endosso enviada para seguradora."
            );
            this.$router.push({
              name: "timeline-proposta",
              params: {
                propostaUniqueId: this.$route.params.uniqueId
              }
            });
          })
          .catch(error => {
            this.loadingButton = false;
            this.termosAceiteModal = false;
            this.$onpoint.errorModal(error.response.data.Errors[0]);
          });
      });
    }
  }
};
</script>

<style lang="scss">
.fix-scroll .vs-popup--content {
  overflow: auto;
}

#confirmacao-modal {
  .fa-exclamation-triangle {
    font-size: 80px;
  }

  .text-confirmation {
    width: 411px;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 87px;

    .line-1 {
      color: #50555a;
    }

    .line-2 {
      color: #868686;
    }
  }
}

#termos-endosso-modal {
  z-index: 52999;
  .vs-popup {
    width: 600px !important;
  }

  .vs-checkbox {
    transform: scale(1.5) !important;
    margin-right: 17px;
  }
}
</style>

<style lang="scss" scoped>
.icon-font::before{
  position: relative;
  top: 2px;
}
</style>